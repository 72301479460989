.transcript {
  height: 700px; /* Set this to whatever height you prefer */
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;

  @media (height: 360px) {
    height: 300px; /* Set this to whatever height you prefer */
  }
}

.segment {
  margin-bottom: 30px;
}

.words {
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 1px;
}

.highlight {
  background-color: rgb(83, 51, 193);
  color: white;
  padding: 3px;
  border-radius: 3px;
}
